import React from 'react'
import DefaultSection from '../components/DefaultSection'
import Article from '../components/Article'
import Seo from '../components/Seo'
import BigTitle from '../components/BigTitle'
import { graphql } from 'gatsby'

const Page = ({ data }) => {
  const post = data.markdownRemark
  return (
    <React.Fragment>
      <Seo title={post.frontmatter.title}/>
      <BigTitle className="bg-level-2" title={post.frontmatter.title}>{post.frontmatter.subTitle}</BigTitle>
      <DefaultSection menuInverted={false} logoInverted={false}>
        <Article>
          <div dangerouslySetInnerHTML={{ __html: post.html }}/>
        </Article>
      </DefaultSection>
    </React.Fragment>
  )
}

export default Page

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: {slug: {eq: $slug}}) {
            html
            frontmatter {
                title
                subTitle
            }
        }
    }
`
